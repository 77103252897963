import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const EnablePlugin = () => {
  return (
    <LayoutComponent>
      <h3>Enable GAINSS Plugin in a JIRA project</h3>
      <div className="testimonials">
        <h3>Note</h3>
        <p>
          You must be a site admin or project admin to enable/disable GAINSS for your project.
        </p>
      </div>
      <ol>
        <li>
          Open your Jira project. Select Project settings from the left side
          menu.
        </li>
        <li>
          Click Gainss-Test Intelligence Solution when the Apps drop-down
          appears.
        </li>
        <li>Click on plugin configuration</li>
        <li>Enable/ disable the plugin</li>
      </ol>
    </LayoutComponent>
  )
}

export default EnablePlugin
